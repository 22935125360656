const JPEG_COMPRESSION = 0.8;

declare global {
  interface Navigator {
    getUserMedia?(
      constrains: MediaStreamConstraints,
      resolve: (stream: MediaStream) => void,
      reject?: (error: Error) => void,
    ): void;
  }
}

export enum FacingMode {
  USER = 'user',
  ENVIRONMENT = 'environment',
}

export async function getUserMediaDevice(constraints: MediaStreamConstraints): Promise<MediaStream> {
  if (window.navigator.mediaDevices) {
    return window.navigator.mediaDevices.getUserMedia(constraints);
  }
  if ('getUserMedia' in window.navigator) {
    return new Promise((resolve, reject) => {
      window.navigator.getUserMedia(constraints, resolve, reject);
    });
  }
  throw new Error('Camera capturing is not supported in this browser.');
}

export function getMediaSize(
  node: HTMLImageElement | HTMLVideoElement | HTMLCanvasElement | ImageBitmap | HTMLElement,
): [number, number] {
  if (node instanceof Image) {
    return [node.naturalWidth, node.naturalHeight];
  }
  if (node instanceof HTMLVideoElement) {
    return [node.videoWidth, node.videoHeight];
  }
  if (node instanceof HTMLElement) {
    const rect = node.getBoundingClientRect();
    return [rect.width, rect.height];
  }
  return [node.width, node.height];
}

export function calculateCropSize(inputWidth: number, inputHeight: number, aspectRatio = 16 / 10): [number, number] {
  let outputWidth = inputWidth;
  let outputHeight = inputHeight;

  // get the aspect ratio of the input image
  const inputImageAspectRatio = inputWidth / inputHeight;

  // if it's bigger than our target aspect ratio
  if (inputImageAspectRatio > aspectRatio) {
    outputWidth = inputHeight * aspectRatio;
  } else if (inputImageAspectRatio < aspectRatio) {
    outputHeight = inputWidth / aspectRatio;
  }
  return [outputWidth, outputHeight];
}

export function cropImageFromSource(
  image: HTMLImageElement | HTMLVideoElement,
  aspectRatio = 16 / 10,
  compression = JPEG_COMPRESSION,
): Promise<string> {
  return new Promise((resolve, reject) => {
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    if (!context) {
      reject('This browser does not support Canvas2D');
      return;
    }
    // let's store the width and height of our image
    const [inputWidth, inputHeight] = getMediaSize(image);
    const [outputWidth, outputHeight] = calculateCropSize(inputWidth, inputHeight, aspectRatio);

    // calculate the position to draw the image at
    const x = (outputWidth - inputWidth) * 0.5;
    const y = (outputHeight - inputHeight) * 0.5;

    canvas.width = outputWidth;
    canvas.height = outputHeight;
    context.drawImage(image, x, y);

    const result = canvas.toDataURL('image/jpeg', compression);
    context = null;
    canvas = null;

    resolve(result);
  });
}

export function cropImage(dataUri: string, aspectRatio = 16 / 10, compression = JPEG_COMPRESSION): Promise<string> {
  return new Promise(resolve => {
    const image = new Image();
    const handler = () => {
      image.removeEventListener('load', handler, false);
      resolve(cropImageFromSource(image, aspectRatio, compression));
    };
    image.addEventListener('load', handler, false);
    image.src = dataUri;
  });
}

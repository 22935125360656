import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RouteEnum from '../../RouteEnum';
import { useRouteContext } from '../../RouteProvider';
import type { ReduxState } from '../../store';
import { flashMessageError, flashMessageSuccess } from '../../store/session';
import { setUserAvatar } from '../../store/user';
import ImageUpload from '../Form/ImageUpload';
import Loader from '../Loader';
import T from '../Translate';

function SetUserAvatar() {
  const dispatch = useDispatch();
  const user = useSelector((state: ReduxState) => state.user);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { navigate, generatePath } = useRouteContext();

  const onSubmit = useCallback(async () => {
    if (loading) return;
    if (!image) {
      dispatch(flashMessageError('profile.photo.requiredText'));
      return;
    }

    setLoading(true);
    try {
      await dispatch(setUserAvatar(image));
      dispatch(flashMessageSuccess());
      navigate(generatePath(RouteEnum.MY_PROFILE));
    } catch (error) {
      dispatch(flashMessageError(error.message));
      setLoading(false);
    }
  }, [dispatch, navigate, image, loading]);

  return (
    <main className="main-container container container--top text-center pb-5">
      <h1 className="display-1 mb-5">
        <T id="picture.photo.title" />
      </h1>

      <div className="my-5">
        <ImageUpload
          accept="image/jpeg"
          containerProps={{
            className: 'dot-info',
            'data-dot-color': 'secondary',
          }}
          height={1200}
          image={user.avatar_document?.cdn_ident}
          imageCompression={0.8}
          onUpload={setImage}
          width={1600}
        />
      </div>

      <div className="mb-4">
        <button className="btn btn-primary px-5" disabled={!image || loading} onClick={onSubmit} type="button">
          {loading ? <Loader dotClassName="bg-white" /> : <T id="global.save" />}
        </button>
      </div>
    </main>
  );
}

export default SetUserAvatar;

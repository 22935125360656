import React from 'react';

import PageLayout from '../../views/Layout/Page';
import SetUserAvatar from '../../views/User/SetUserAvatar';

export default function ProfileGeneralPage() {
  return (
    <PageLayout pageview="my_profile_avatar" private>
      <SetUserAvatar />
    </PageLayout>
  );
}
